<template>
  <div class="openZD" v-cloak>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <van-loading size="24px">加载中...</van-loading>
      </div>
    </van-overlay>
    <van-nav-bar
      fixed
      :border="false"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="title">
      {{ title }}
    </div>
    <div class="content" v-if="!show">
      <div v-html="content" style="font-size: 14px; line-height: 30px"></div>
      <div class="money">{{ money }} {{ currency_name }}</div>
      <div class="img">
        <img src="../../assets/img/openzd_bg1.png" alt="" width="100%" />
      </div>
      <div class="btn" @click="activateNow()">{{ $t("TaskPopup[28]") }}</div>
      <div class="desc">{{ $t("TaskPopup[27]") }}</div>
    </div>
  </div>
</template>

<script>
/* 开通自动抢单页面和自动完成订单页面 */
export default {
  name: "openZD",
  data() {
    return {
      title: "",
      content: "", //页面内容
      money: "",
      cost: "",
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号
      show: true, //遮罩层
    };
  },
  created() {
    console.log(window.localStorage.getItem("Language"));
    if (this.$route.query.type == 0) {
      window.localStorage.setItem("target", "3"); // 3 开通自动抢单 4开通自动完成
      //开通自动抢单
      this.$Model.getOpenzdContent(
        { lang: window.localStorage.getItem("Language") },
        (res) => {
          this.title = this.$t("automaticComplete[0]");
          this.content = res.ability;
          this.money = this.$t("openComplete[0]") + res.cost;
          this.cost = res.cost;
          this.show = false;
        }
      );
      return;
    }
    window.localStorage.setItem("target", "4");
    this.$Model.getOpenzdSContent(
      { lang: window.localStorage.getItem("Language") },
      (res) => {
        this.title = this.$t("automaticComplete[1]");
        this.content = res.ability;
        this.money = this.$t("openComplete[0]") + res.cost;
        this.cost = res.cost;
        this.show = false;
      }
    );
  },
  destroyed() {
    window.localStorage.removeItem("target");
  },
  components: {},
  methods: {
    activateNow() {
		console.log('this.$route.query.type',this.$route.query.type)
		if(this.$route.query.type==0){
			window.localStorage.setItem("target", "3"); // 3 开通自动抢单 4开通自动完成
		}else{
			window.localStorage.setItem("target", "4"); // 3 开通自动抢单 4开通自动完成
		}
      this.$router.push("user/mixRecharge?m=" + this.cost);
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
[v-cloak] {
  display: none;
}
.openZD {
  height: 100vh;
  background: cornsilk url("~@/assets/img/openzd_bg.png") no-repeat center/cover;
}
.openZD > .title {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
.openZD > .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 5px;
  line-height: 50px;
  margin: 0 auto;
  margin-top: 20px;
  width: 310px;
  height: 80%;
  border-radius: 14px;
  background: linear-gradient(180deg, #ffffff, #ffffff);
}
.openZD > .content > .img {
  margin-top: 40px;
}
.openZD > .content > div:first-child,
.openZD > .content > div:nth-child(2) {
  width: 100%;
  padding-left: 20px;
  color: #333333;

  font-size: 16px;
  font-weight: 400;
}
.openZD > .content > .btn {
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 243px;
  height: 44px;
  border-radius: 16px;
  background: #008fff;
  color: #ffffff;

  font-size: 18px;
  font-weight: 400;
  line-height: 44px;
}
.openZD > .content > .desc {
  line-height: normal;
  position: absolute;
  bottom: 10px;
  color: #999999;

  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.openZD > .content > .money {
  margin-top: 20px;
  width: 100%;
  text-align: left;
  color: #333333;

  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
/* vant ui 组件内部样式调整 */
::v-deep .van-nav-bar {
  background: transparent;
}
::v-deep .van-icon-arrow-left::before {
  font-size: 24px;
  color: #fff;
}
</style>
